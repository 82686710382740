import React from 'react'
// import { get } from 'lodash'
// import Link from 'gatsby-link'
// import moment from 'moment'
// import {
//   Body,
//   ContentWrapper,
//   Layout,
//   PublishDate,
//   Title,
//   Wrapper,
// } from '../styles/pressreleases.style'

// class PressReleases extends Component {
//   render() {
//     return (
//       <div>Hello</div>
//     )
//     // const pressReleases = get(this.props, 'data.allContentfulPressRelease.edges', [])

//     // return (
//     //   <Layout>
//     //     <Wrapper>
//     //       <ContentWrapper>
//     //         <Title>Press Releases</Title>
//     //         <Body>
//     //           {pressReleases.length === 0 ? (
//     //             'There are no press releases to show at this time'
//     //           ) : (
//     //             <ul>
//     //               {pressReleases.map(({ node: release }) => (
//     //                 <li key={release.slug}>
//     //                   <Link to={`/${release.slug}`}>{release.title}</Link>
//     //                   <br />
//     //                   <PublishDate>
//     //                     {moment(release.publishDate).format('MMMM Do YYYY')}
//     //                   </PublishDate>
//     //                 </li>
//     //               ))}
//     //             </ul>
//     //           )}
//     //         </Body>
//     //       </ContentWrapper>
//     //     </Wrapper>
//     //   </Layout>
//     // )
//   }
// }

// export default PressReleases

// // export const query = graphql`
// //   query PressReleasesQuery {
// //     allContentfulPressRelease {
// //       edges {
// //         node {
// //           title
// //           slug
// //           publishDate
// //         }
// //       }
// //     }
// //   }
// // `

export default () => <div />